import React, { useState } from "react"

import {
  Box,
  Button,
  Drawer,
  Heading,
  InputField,
  List,
  ListItem,
  Stack,
  Text,
  TextLink,
  Tooltip,
} from "@kiwicom/orbit-components"

import {
  DateRange,
  EmberDateRangePicker,
} from "components/generic/date-time/ember-date-range-picker"
import { AdminLayout } from "components/layout-custom"
import { TertiaryNavBar } from "components/nav-bar-tertiary"
import { BulkShiftEditor } from "components/rota/bulk-shift-editor/bulk-shift-editor"
import { FullScreenWrapper } from "components/rota/bulk-shift-editor/full-screen-wrapper"

const Page = () => {
  const [dateRange, setDateRange] = useState<DateRange>({
    start: new Date(),
    end: new Date(),
  })

  const [startTimeFilter, setStartTimeFilter] = useState("")
  const [endTimeFilter, setEndTimeFilter] = useState("")

  const [showHelpDrawer, setShowHelpDrawer] = useState(false)

  return (
    <AdminLayout title="Bulk Shift Editor" padded={false}>
      <Drawer
        shown={showHelpDrawer}
        title="Help"
        onClose={() => setShowHelpDrawer(false)}
        lockScrolling={false}
      >
        <Stack>
          <Text>
            This is an advanced feature that should be used for caution. You can
            easily break lots of shifts if you are not careful before applying
            changes.
          </Text>
          <Text>A few quick notes:</Text>
          <List>
            <ListItem>All dates, times and time filters are in UTC</ListItem>
            <ListItem>
              If you set a Shift ID to blank then a new shift will be created
              for that row
            </ListItem>
            <ListItem>
              Leaving a field blank means it will not be updated
            </ListItem>
            <ListItem>
              To clear a string field (e.g.&nbsp;a date or note) set it to
              'null'
            </ListItem>
            <ListItem>
              To remove an object from a field that accepts an array
              (e.g.&nbsp;trips or activities), you'll need to set a flag inside
              the relevant object
            </ListItem>
          </List>
          <Text>
            Refer to the{" "}
            <TextLink
              external
              href="https://developer.ember.to/docs/api-documentation/ca08bc7a44a30-bulk-update-shifts"
            >
              API documentation
            </TextLink>{" "}
            for further details.
          </Text>
        </Stack>
      </Drawer>
      <TertiaryNavBar
        leftContent={
          <Stack direction="row">
            <Heading type="title3">Bulk Shift Editor (Advanced)</Heading>
          </Stack>
        }
        rightContent={
          <Stack direction="row" align="center" inline spacing="XLarge">
            <Stack direction="row" spacing="small" align="center" inline>
              <Tooltip content="All shifts for the date range will be loaded. They can be filtered using the time filters. Avoid selecting a range of more than 2-3 weeks for performance reasons.">
                <Text weight="bold">Date Range</Text>
              </Tooltip>
              <EmberDateRangePicker
                range={dateRange}
                setRange={setDateRange}
                showDay={false}
                showTwoMonths
              />
            </Stack>
            <Stack direction="row" align="center" inline>
              <Tooltip content="Will do an exact match of start/end time of the shift against the text entered. Text should be formatted as 'HH:mm'">
                <Text weight="bold">Time Filters</Text>
              </Tooltip>
              <Box width="80px">
                <InputField
                  onChange={(e) => setStartTimeFilter(e.currentTarget.value)}
                  value={startTimeFilter}
                  placeholder="Start"
                  inlineLabel
                />
              </Box>
              <Box width="80px">
                <InputField
                  onChange={(e) => setEndTimeFilter(e.currentTarget.value)}
                  value={endTimeFilter}
                  placeholder="End"
                  inlineLabel
                />
              </Box>
            </Stack>
            <Button type="secondary" onClick={() => setShowHelpDrawer(true)}>
              Show Help
            </Button>
          </Stack>
        }
      />
      <FullScreenWrapper>
        <BulkShiftEditor
          dateRange={dateRange}
          shiftStartTimeFilter={startTimeFilter}
          shiftEndTimeFilter={endTimeFilter}
          type="ShiftEditing"
          style="fullPage"
        />
      </FullScreenWrapper>
    </AdminLayout>
  )
}

export default Page
