// @ts-strict-ignore
import React, { useState } from "react"

import { format } from "date-fns-tz"

import { Box, Popover, useMediaQuery } from "@kiwicom/orbit-components"
import InputField from "@kiwicom/orbit-components/lib/InputField"

import { ActionLink } from "../action-link"
import { EmberInlineDateRangePicker } from "../date-time/ember-inline-date-picker"

export interface DateRange {
  start: Date
  end: Date
}

interface EmberDateRangePickerProps
  extends React.ComponentProps<typeof InputField> {
  range: DateRange
  setRange: (value: DateRange) => void
  minDate?: Date
  maxDate?: Date
  fullWidth?: boolean
  showDay?: boolean
  showTwoMonths?: boolean
  shrinkOnMobile?: boolean
  actionLink?: any
}

const EmberDateRangePicker = ({
  range,
  setRange,
  minDate,
  maxDate,
  fullWidth = false,
  showDay = true,
  showTwoMonths = false,
  shrinkOnMobile = false,
  ...props
}: EmberDateRangePickerProps) => {
  const { isLargeMobile } = useMediaQuery()

  const dateFormat =
    shrinkOnMobile && !isLargeMobile
      ? "dd/MM/yy"
      : showDay
      ? "eee d MMM yyyy"
      : "d MMM yyyy"
  const [isOpen, setIsOpen] = useState(false)

  function displayValue() {
    if (range) {
      return `${format(range.start, dateFormat)} - ${format(
        range.end,
        dateFormat
      )}`
    } else {
      return null
    }
  }

  return (
    // Outer div important to make component work as last item in horizontal stack
    // Without it, you end up with padding to the right when the popover is opened
    // due to an extra final element appearing in the dom
    <div>
      <Popover
        allowOverflow={false}
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        renderInPortal={false}
        noPadding
        lockScrolling={false}
        content={
          <EmberInlineDateRangePicker
            range={range}
            setRange={setRange}
            minDate={minDate}
            maxDate={maxDate}
            numberMonths={showTwoMonths ? 2 : 1}
            closeOnSelect={() => setIsOpen(false)}
          />
        }
      >
        <Box
          width={
            fullWidth
              ? ""
              : shrinkOnMobile && !isLargeMobile
              ? "170px"
              : showDay
              ? "306px"
              : "230px"
          }
        >
          {props.actionLink != null && (
            <Box bottom="1px" position="relative">
              <ActionLink actionLink={props.actionLink} />
            </Box>
          )}
          <div onClick={() => setIsOpen(true)}>
            <InputField {...props} value={displayValue()} readOnly={true} />
          </div>
        </Box>
      </Popover>
    </div>
  )
}

export { EmberDateRangePicker }
